.w-20 {
    width: 20%!important;
}
.w-15 {
    width: 15%!important;
}
.w-10 {
    width: 10%!important;
}
.w-5 {
    width: 5%!important;
}
.pt-30 {
    padding-top: 30px;
}
.pt-50 {
    padding-top: 50px;
}
.bg-teal {
    background-color: var(--bs-teal);
}
.bg-red {
    background-color: var(--bs-red);
}
.bg-orange {
    background-color: var(--bs-orange);
}
.bg-pink {
    background-color: var(--bs-pink);
}
.bg-purple {
    background-color: var(--bs-purple);
}
.bg-green {
    background-color: var(--bs-green);
}
.bg-cyan {
    background-color: var(--bs-cyan);
}
.bg-blue {
    background-color: var(--bs-blue);
}
.bg-indigo {
    background-color: var(--bs-indigo);
}
.bg-yellow {
    background-color: var(--bs-yellow);
}
.display-block {
    display : block !important;
}
.inputCard {
    background-color:transparent;
}

/* Page Tabs */
.myTabLink:focus {
    background-color: var(--bs-indigo) !important;
    color: #FFF !important;
}
.nav-link {
    font-size: medium !important;
    padding-left: 20px !important;
    padding-right: 20px !important;
}

/* Navbar left */
.navbarIconContainer {
    position: relative;
    display: block;
    width: 32px;
    height: 32px;
    border-radius: 2px;
    flex-shrink: 0;
    overflow: hidden;
    text-align: center;
}
.navbarName {
    overflow: hidden;
    flex-grow: 1;
    margin-left: 1em;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding-left: 0.5em;
}
.navbarLink {
    display: flex;
    min-width: 40px;
    width: 100%;
    padding: 2px;
    margin-top: 1px;
    color: inherit;
    align-items: center;
    overflow: hidden;
    border: none;
    border-radius: 3px;
    background-color: transparent;
    text-align: left;
    font-size: small;
}
.navbarLink:not(:disabled):focus, .navbarLink:not(:disabled):hover {
    color: white !important;
    text-decoration: none !important;
}
.navbarItem {
    display: flex;
    width: 100%;
    padding: 4px;
    margin-top: 1px;
    color: inherit;
    align-items: center;
    overflow: hidden;
    border: none;
    border-radius: 3px;
    background-color: transparent;
    text-align: left;
}
.navbarItem:not(:disabled):hover {
    background-color: rgba(255,255,255,.2);
}
.navbarIcon {
    height: 32px;
    width: 32px;
    line-height: 32px;
    position: absolute;
    display: block;
    text-align: center;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

/* Navbar Top */
.myNavbar {
    background-color: #151515 !important;
    /*background-color: #355D39 !important;*/
    height: 80px;
}
.navbar-logo {
    filter: brightness(175%);
}
.topbar {
    flex: 1 0 auto;
    padding-left: 25px;
}
.toolbar {
    color: #f5f5f5;
    display: block;

}
.toolbar-list {
    list-style: none;
    box-sizing: border-box;
    margin-top: 5px;
}
.toolbar-item {
    width: auto;
    margin-left: 1px;
    float:left;
    display: block;
    position: relative;
}
.toolbar-item-link {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    position: relative;
    display: block;
    text-decoration: none;
    padding: 9px 10px;
    line-height: 25px;
}

@media (max-width: 700px) {
    .card-columns.custom-columns {
        column-count: 1;
    }
}
@media (min-width: 700px) {
    .card-columns.custom-columns {
        column-count: 2;
    }
}
@media (min-width: 1000px) {
    .card-columns.custom-columns {
        column-count: 3;
    }
}
@media (min-width: 1400px) {
    .card-columns.custom-columns {
        column-count: 4;
    }
}