.header {
    height: 120px;
    padding: 0 60px;
}

.nav-link {
    font-size: larger;
    color: gray;
    padding-right: 0px;
    padding-left: 30px;
}

/* Hero & large images */
.hero {
    height: 500px;
    position: relative;
}
.hero ul {
    list-style: none;
}
.hero-text {
    position: absolute;
    width: 100%;
    top: 10px;
}
.backgroundSchiff {
    
    height: 400px;
}
.coffee {
    max-height: 200px;
}
@media (max-width: 500px) {
    .hero h2 {
        font-size: 14pt;
    }
    .hero h1 {
        font-size: 20pt;
    }
}
@media (min-width: 500px) and (max-width: 1000px) {
    .hero {
        height: 667px; /* 550px; */
    }
    .hero h2 {
        font-size: 20pt;
    }
    .hero h1 {
        font-size: 30pt;
    }
    .backgroundSchiff {
        background-image: url("../images/schiff.jpeg");
        background-repeat: no-repeat;
        background-size: cover;
        height: 450px;
    }
}
@media (min-width: 500px) and (max-width: 780px) {
    .hero {
        height: 550px; /* 550px; */
    }
    .hero h2 {
        font-size: 20pt;
    }
    .hero h1 {
        font-size: 30pt;
    }
    .backgroundSchiff {
        background-image: url("../images/schiff.jpeg");
        background-repeat: no-repeat;
        background-size: cover;
        height: 450px;
    }
}
@media (min-width: 780px) and (max-width: 1000px) {
    .hero {
        height: 667px; /* 550px; */
    }
    .hero h2 {
        font-size: 20pt;
    }
    .hero h1 {
        font-size: 30pt;
    }
    .backgroundSchiff {
        background-image: url("../images/schiff.jpeg");
        background-repeat: no-repeat;
        background-size: cover;
        height: 450px;
    }
}
@media (min-width: 1000px) and (max-width: 1300px) {
    .hero {
        height: 700px; /* alt:850 */
    }
    .hero h2 {
        font-size: 28pt;
    }
    .hero h1 {
        font-size: 44pt;
    }
    .backgroundSchiff {
        background-image: url("../images/schiff.jpeg");
        background-repeat: no-repeat;
        background-size: cover;
        height: 550px;
    }
    .coffee {
        max-height: 450px;
    }
}
@media (min-width: 1300px) and (max-width: 1500px) {
    .hero {
        height: 850px; /* alt:850 */
    }
    .hero h2 {
        font-size: 32pt;
    }
    .hero h1 {
        font-size: 48pt;
    }
    .backgroundSchiff {
        background-image: url("../images/schiff.jpeg");
        background-repeat: no-repeat;
        background-size: cover;
        height: 500px;
    }
    .coffee {
        max-height: 500px;
    }
}
@media (min-width: 1500px) {
    .hero {
        height: 1010px;
    }
    .hero h2 {
        font-size: 40pt;
    }
    .hero h1 {
        font-size: 56pt;
    }
    .backgroundSchiff {
        background-image: url("../images/schiff.jpeg");
        background-repeat: no-repeat;
        background-size: cover;
        height: 800px;
    }
    .coffee {
        max-height: 500px;
    }
}


.heroLink {
    color: #FFF;
}
.heroLink:hover {
    color: lightgray;
}

.heroTitle {
    color: #355D39;
}
.heroSubTitle {
    color: #355D39;
}



.pl-0-i {
    padding-left: 0px !important;
}

.siegel {
    max-width: 40%;
    position: absolute;
    top: 450px;
    left:370px;
}

.logo {
    max-height: 120px;
 }



.infoCircleText1 {
    display: block;
    font-size: xx-large;
    font-weight: bolder;
}
.infoCircleText2 {
    display: block;
    font-size: x-large;
}

.infoKarten > div {
    margin-top: 60px;
}

.infoKarten > div > div {
    background-color: #fff;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 60px;
    padding-right: 60px;
    height: 100%;
}

/* Articles */
.articleSeperator {
    height: 40px;
    background-image: linear-gradient(
        rgb(238, 237, 237),
        white
    );
}
.article {
    padding-bottom: 60px;
}
.article2 {
    background-color: #faf9f5;
    padding-bottom: 60px;
}
.article3 {
    background-color: #355D39;
    color: #fff;
    padding-top: 70px;
    padding-bottom: 70px;
}
.articleSeperator2 {
    height: 40px;
    background-image: linear-gradient(
        rgb(238, 237, 237),
        #faf9f5
    );
}

/* BG Colors */
.backgroundGreen {
    background-color: #D4DFC7;
}
.backgroundSand {
    background-color: #faf9f5;
}
.backgroundSandDark {
    background-color: hwb(53 93% 0% / 0.966) !important;
}

/* Footer */
.footer {
    background-color: #355D39;
    min-height: 500px;
    color: #fff;
    padding-top: 70px;
}
.footer ul {
    list-style: none;
}

/* Akkreditierungen */
.staaten {
    border-bottom: 1px solid;
 }
 .staatenCounter {
    color: #355D39;
    font-weight: 800;
    font-size: xx-large;
    margin-right: 15px;
 }
 .akkreditierungStelle {
    color: #355D39;
    font-family: Outfit, sans-serif;
 }
 .akkreditierungName {
    font-size: small;
 }
 .akkreditierungLink {
    font-family: Outfit, sans-serif;
    text-decoration: underline;
 }
 .akkreditierungLink:hover {
    color: #355D39;
 }

 /* Kontakt */
.visitenKarten > div {
    margin-top: 40px;
    margin-bottom: 20px;
    margin-left: 20px;
    margin-right: 20px;
}
.infoKarten > div > div:hover {
    background-color: #D4DFC7;
}

.visitenKarten > div > div {
    background-color: #faf9f5;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 40px;
}
.mapColumn {
    padding-left: 0px;
    padding-right: 0px;
}
#dsRow .form-check .form-check-label {
    font-size: smaller;
}

/* Toggle Buttons */
.toggleLink {
    color:#355D39;
    font-family: Outfit, sans-serif;
}
.toggleLeistungen[aria-expanded=false] .text-expanded {
    display: none;
}
.toggleLeistungen[aria-expanded=true] .text-collapsed {
    display: none;
}
.toggleHalal[aria-expanded=false] .text-expanded {
    display: none;
}
.toggleHalal[aria-expanded=true] .text-collapsed {
    display: none;
}
.togglePartner[aria-expanded=false] .text-expanded {
    display: none;
}
.togglePartner[aria-expanded=true] .text-collapsed {
    display: none;
}

/* Headers */
h1, h2, h3 {
    color:#355D39;
    font-family: Outfit, sans-serif;
    font-weight: 800;
}
h2 {
    font-size: var(--headers-font-size);
}
h3 {
    font-size: var(--headers-font-size-med);
}
h4 {
    font-weight: 500;
    margin-top: 18px;
    margin-bottom: 2px;
    font-size: 1.2rem;
}

a {
    text-decoration: none;
    transition: .5s;
    color: gray;    
}

.display-block {
    display: block !important;
}

.infoCircleText1 {
    display: block;
    font-size: larger;
    font-weight: bolder;
}
.infoCircleText2 {
    display: block;
    font-size: larger;
}
.infoCircle {
    width: var(--info-circle-width);
}

:root {
    --main-font-size: 0.7rem;
    --main-line-height: 160%;
    --headers-font-size: 1.1rem;
    --headers-font-size-med: 0.9rem;
    --info-circle-width: 30%;
}

body {
    font-family: Poppins, sans-serif;
    font-size: var(--main-font-size);
    line-height: var(--main-line-height);
}

@media screen and (min-width: 800px) and (max-width: 1024px) {
    :root {
        --main-font-size: 0.9rem;
        --headers-font-size: 1.5rem;
        --headers-font-size-med: 1.2rem;
        --info-circle-width: 50%;
    }
}
@media screen and (max-width: 1024px) {
    .bsGutterX {
        --bs-gutter-x: 2rem;
    }
    .publicBtn {
        --bs-btn-padding-y: .25rem;
        --bs-btn-padding-x: .5rem;
        --bs-btn-font-size: .8rem;
    }
}
@media screen and (min-width: 1025px) and (max-width: 1200px){
    .bsGutterX {
        --bs-gutter-x: 4rem;
    }
    :root {
        --main-font-size: 0.9rem;
        --headers-font-size: 1.8rem;
        --headers-font-size-med: 1.4rem;
        --info-circle-width: 55%;
    }
    .publicBtn {
        --bs-btn-font-size: 1rem;
    }
}
@media screen and (min-width: 1200px) and (max-width: 1400px){
    .bsGutterX {
        --bs-gutter-x: 6rem;
    }
    :root {
        --main-font-size: 1rem;
        --headers-font-size: 2rem;
        --headers-font-size-med: 1.6rem;
        --info-circle-width: 50%;
    }
    .publicBtn {
        --bs-btn-font-size: 1.1rem;
    }
}
@media screen and (min-width: 1400px){
    .bsGutterX {
        --bs-gutter-x: 8rem;
    }
    :root {
        --main-font-size: 1.05rem;
        --headers-font-size: 2.1rem;
        --headers-font-size-med: 1.7rem;
        --info-circle-width: 55%;
    }
    .publicBtn {
        --bs-btn-font-size: 1.3rem;
    }
}
@media only screen and (min-width: 1921px) {
    main {
        width: 1920px;
        margin-left: auto;
        margin-right: auto;
    }
    :root {
        --main-font-size: 1.15rem;
        --headers-font-size: 2.4rem;
        --headers-font-size-med: 1.8rem;
        --info-circle-width: 60%;
    }
    .publicBtn {
        --bs-btn-font-size: 1.4rem;
    }
}

/* Classes for Webapp */
